// external
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Paper, Skeleton, useTheme } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// components
import { GroupCard, PageCard } from 'components';

// store
import { useAppSelector } from 'store/hooks';
import { selectGroupGuideForm } from 'store/slices/formsSlice';

// hooks
import { useLocalization, useMobileMediaQuery, useSkills } from 'hooks';

// types
import {
  EProgramVariation,
  ICustomField,
  IMembership,
  IRelationship,
} from '@guider-global/shared-types';

import { getSubDomain } from '@guider-global/front-end-utils';
import { useOrganizationPrograms } from '@guider-global/sanity-hooks';
import {
  useCustomFields,
  useMemberships,
  useProfiles,
} from '@guider-global/front-end-hooks';
import { useRelationships } from '@guider-global/front-end-hooks';
import { ITypedApiResult } from '@guider-global/swr';

export const GuideGroupPreviewPage: React.FC = () => {
  // Utils
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  // Styling
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  // React router
  const navigate = useNavigate();
  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  // Internal hooks
  // Profiles
  const { profiles } = useProfiles({});
  const profile = profiles?.at(0);
  const profileId = profile?.id ?? '';
  const profilePicture = profile?.picture;
  // Memberships
  const {
    isLoadingMemberships,
    reqMemberships,
    isMutatingMemberships,
    membershipsRevalidate,
  } = useMemberships({});
  // Skills
  const { isLoadingSkills } = useSkills({});
  // Relationships
  const { reqRelationships, isLoadingRelationships, isMutatingRelationships } =
    useRelationships({});

  // Custom fields
  const { reqCustomFields, isLoadingCustomFields, isMutatingCustomFields } =
    useCustomFields({});

  const isLoading =
    isLoadingCustomFields ||
    isLoadingMemberships ||
    isLoadingRelationships ||
    isLoadingSkills;
  const isMutating =
    isMutatingCustomFields || isMutatingMemberships || isMutatingRelationships;

  // Programs
  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);
  // Events

  // Redux
  const groupGuideForm = useAppSelector((state) =>
    selectGroupGuideForm(state, programSlug),
  );

  useEffect(() => {
    if (!groupGuideForm) {
      navigate('./../');
    }
  }, [groupGuideForm, navigate]);

  const isGuide = role === 'guide';
  useEffect(() => {
    if (!isGuide) {
      navigate(`/programs/${programSlug}`);
    }
  }, [isGuide, navigate, programSlug]);

  if (!program || !role) {
    return <></>;
  }

  if (!groupGuideForm) {
    return <></>;
  }

  const programType = program?.program_details?.program_type;
  const programTypeCopy = programType?.program_type_text;
  const requiresApproval =
    program.registration?.guide_membership_approval ?? false;

  const groupCopy = programTypeCopy?.variations?.group;

  const publishGroup = async () => {
    let customFields: ICustomField[] = [];

    const {
      title,
      description,
      skills: formSkills,
      ...formCustomFields
    } = groupGuideForm;

    const customFieldsPostData: Partial<ICustomField>[] = Object.entries(
      formCustomFields,
    ).map(([fieldKey, fieldValue]) => {
      const fieldType = program?.registration?.registration_questions?.find(
        (registrationQuestion) => registrationQuestion.id.current === fieldKey,
      )?.type;

      return {
        fieldSlug: fieldKey,
        organizationSlug,
        programSlug,
        fieldType,
        value: fieldValue,
        profileId,
      };
    });

    if (customFieldsPostData.length > 0) {
      const customFieldsResponse = await reqCustomFields({
        method: 'POST',
        url: '/customfields',
        data: [...customFieldsPostData],
      });

      Array.isArray(customFieldsResponse.data) &&
        (customFields = customFieldsResponse.data);
    }

    const customFieldIds: string[] | undefined = customFields.map(
      (customField) => customField.id,
    );

    reqRelationships(
      {
        method: 'PUT',
        data: {
          guideProfiles: [profileId],
          isConcluded: false,
          programSlug,
          organizationSlug,
          programTypeSlug: programType?.metadata?.id?.current,
          programVariationTypeSlug: EProgramVariation.Group,
          profileId,
          title,
          description,
        },
      },
      {
        onSuccess: (result, key) => {
          const relationshipId = result.data.at(0)?.id;
          const membershipData: Partial<IMembership> & {
            skillSlugs: string[];
          } = {
            role: 'guide',
            organizationSlug,
            programSlug,
            profile: profileId,
            isPublished: true,
            programFields: customFieldIds ?? [],
            skillSlugs: formSkills ?? [],
            requiresApproval: requiresApproval,
            programVariationTypeSlug: EProgramVariation.Group,
          };

          reqMemberships(
            {
              method: 'POST',
              url: '/memberships',
              data: membershipData,
            },
            {
              onSuccess: () => {
                membershipsRevalidate();
                navigate(`/relationships/${relationshipId}`);
              },
              onError: () => {},
            },
          );
        },
        populateCache: (result, currentData) => {
          const resultIds = result.data.map((relationship) => relationship.id);
          const filterOutNewRelationships =
            currentData?.data.filter((relationship) => {
              return !resultIds.includes(relationship.id);
            }) ?? [];

          const newCache = {
            ...currentData,
            data: [...filterOutNewRelationships, ...result.data],
          } as ITypedApiResult<IRelationship[]>;
          return newCache;
        },
      },
    );
  };

  return (
    <Paper
      sx={{
        pt: isMobile ? 0 : 4,
        pb: 4,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
        ...(isMobile && { borderRadius: '0px' }),
      }}
    >
      <PageCard
        title={
          groupCopy?.registration?.registration_guide
            ?.registration_guide_review_profile
            ?.registration_guide_review_profile_title ?? ''
        }
        subtitle={
          groupCopy?.registration?.registration_guide
            ?.registration_guide_review_profile
            ?.registration_guide_review_profile_description ?? ''
        }
      />
      {!isMobile && <Divider sx={{ my: 4, mx: 3 }} />}
      {profile ? (
        <GroupCard
          userRole={role}
          guide={{
            displayName: profile.displayName,
            jobTitle: profile.jobTitle,
            picture: profilePicture,
            townOrCity: profile.townOrCity,
            linkedInUrl: profile.linkedInUrl,
            organizationFields: profile.organizationFields,
          }}
          data={groupGuideForm}
          sx={{ mx: isMobile ? 2 : 3 }}
        />
      ) : (
        <Skeleton height={200} sx={{ transform: 'none' }} />
      )}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          mt: 3,
          px: isMobile ? 2 : 3,
        }}
      >
        <Button
          data-cy="pages-ProgramRegistrationPage-Guide-GuideGroupPreviewPage_navigation-button"
          variant="outlined"
          color="info"
          size="large"
          fullWidth={isMobile}
          onClick={() => navigate('./../')}
        >
          {
            groupCopy?.registration?.registration_guide
              ?.registration_guide_review_profile
              ?.registration_guide_review_profile_edit_profile_button_label
          }
        </Button>
        <LoadingButton
          data-cy="pages-ProgramRegistrationPage-Guide-GuideGroupPreviewPage_submit-button"
          variant="contained"
          color="info"
          size="large"
          type="submit"
          sx={{
            mb: isMobile ? 2 : 0,
          }}
          fullWidth={isMobile}
          onClick={publishGroup}
          loading={isMutating || isLoading}
        >
          {
            groupCopy?.registration?.registration_guide
              ?.registration_guide_review_profile
              ?.registration_guide_review_profile_publish_profile_button_label
          }
        </LoadingButton>
      </Box>
    </Paper>
  );
};
