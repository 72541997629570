// external
import { useParams } from 'react-router-dom';

// internal
import React from 'react';
import {
  GuideGroupCreatePage,
  GuideRegistrationAboutYouPage,
  TraineeGroupJoinPage,
  TraineeRegistrationAboutYouPage,
} from 'pages';
import { useOrganizationPrograms } from '@guider-global/sanity-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useLocalization } from 'hooks';

export const ProgramRegistrationAboutYouPage: React.FC = () => {
  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);

  const programVariation = program?.program_details?.program_variation;
  const isGroupProgram = programVariation === 'group';
  const isGuide = role === 'guide';

  const GuideComponent = isGroupProgram
    ? GuideGroupCreatePage
    : GuideRegistrationAboutYouPage;

  const TraineeComponent = isGroupProgram
    ? TraineeGroupJoinPage
    : TraineeRegistrationAboutYouPage;

  return isGuide ? <GuideComponent /> : <TraineeComponent />;
};
