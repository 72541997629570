import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import {
  Button,
  MultiSelectAreaInputValue,
  RoleChip,
  SideBarIndicator,
  SkillCategoriesView,
  TextStack,
} from '@guider-global/ui';
import { Stack } from '@guider-global/ui/lib/components/Layout/Stack';
import { useLocalization } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressContainer } from 'containers';
import { getSubDomain } from '@guider-global/front-end-utils';
import { SubmitHandler, useForm } from 'react-hook-form';
import guideAvatar from 'assets/guideAvatar.svg';
import traineeAvatar from 'assets/traineeAvatar.svg';
import { useContext, useEffect } from 'react';
import {
  useMemberships,
  useRelationships,
} from '@guider-global/front-end-hooks';
import {
  EProgramVariation,
  IMembership,
  ISkill,
} from '@guider-global/shared-types';
import { interpolate } from 'functions';
import { GroupsContext, GroupValues } from 'containers/Groups';

type SkillFormValues = {
  [key: string]: MultiSelectAreaInputValue;
};

export function ProgramSkillsPage() {
  const navigate = useNavigate();
  const { groupValues, setGroupValues } = useContext(GroupsContext);

  const {
    programSlug = '',
    role,
    type,
    id,
  } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
    type: 'hard' | 'soft';
    id: string | undefined;
  }>();

  const skillType = type === 'soft' ? 'soft_skills' : 'hard_skills';

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { reqMemberships, memberships, isMutatingMemberships } = useMemberships(
    {},
  );
  const { reqRelationships, relationships, isMutatingRelationships } =
    useRelationships({});

  const membership = memberships?.find(
    (membership) =>
      membership?.programSlug === programSlug && role === membership?.role,
  );

  const relationship = relationships?.find(
    (relationship) => relationship?.id === id,
  );

  // Program
  const { getProgram } = useOrganizationPrograms({
    localeCode,
    organizationSlug,
  });
  const program = getProgram(programSlug);

  const isGroup =
    program?.program_details?.program_variation === EProgramVariation.Group;

  const validationSettings =
    type === 'hard'
      ? program?.skills?.config?.hard_skills_validation
      : program?.skills?.config?.soft_skills_validation;

  const min = validationSettings?.minimum_selections;
  const max = validationSettings?.maximum_selections;
  const maxSkillsErrorMessage = interpolate(
    baseLanguage?.globals?.errors?.maximum_selections_exceeded,
    { number: `${max}` },
    'Please select less options',
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm<SkillFormValues>({
    mode: 'onChange',
  });

  const onSubmit: SubmitHandler<SkillFormValues> = async (
    data: SkillFormValues,
  ) => {
    if (!type) return;

    const skills = data?.[skillType]?.value;

    if (isGroup && role === 'guide') {
      if (id) {
        const existingSkillsOfDifferentType = (
          relationship?.skills as Partial<ISkill>[]
        )
          ?.filter(
            (skill) =>
              skill?.fieldSlug &&
              (type === 'soft' ? skill.type === 'hard' : skill.type === 'soft'),
          )
          ?.map((skill) => skill.fieldSlug);

        await reqRelationships({
          method: 'PATCH',
          url: `/relationships/${id}`,
          data: {
            ...(type === 'soft' && {
              softSkillSlugs: skills,
              hardSkillSlugs: existingSkillsOfDifferentType as string[],
            }),
            ...(type === 'hard' && {
              softSkillSlugs: existingSkillsOfDifferentType as string[],
              hardSkillSlugs: skills,
            }),
          },
        });
        if (type === 'soft') {
          return navigate(`../skills/hard`);
        } else {
          return navigate(`/relationships/${id}`);
        }
      } else {
        const groupData: GroupValues = {
          ...groupValues,
          ...(type === 'soft' && {
            softSkillSlugs: skills,
          }),
          ...(type === 'hard' && {
            hardSkillSlugs: skills,
          }),
        };
        setGroupValues(groupData);
        if (type === 'soft') {
          return navigate(`../skills/hard`);
        } else {
          return navigate(`../preview-group`);
        }
      }
    }

    const existingSkillsOfOppositeTypeSlugs = (
      membership?.skills as Partial<ISkill>[]
    )
      ?.filter(
        (skill) =>
          skill?.fieldSlug &&
          (type === 'soft' ? skill.type === 'hard' : skill.type === 'soft'),
      )
      ?.map((skill) => skill.fieldSlug);

    const membershipData: Partial<IMembership> & {
      hardSkillSlugs?: string[];
      softSkillSlugs?: string[];
    } = {
      ...(type === 'soft' && {
        softSkillSlugs: skills,
        hardSkillSlugs: existingSkillsOfOppositeTypeSlugs as string[],
      }),
      ...(type === 'hard' && {
        softSkillSlugs: existingSkillsOfOppositeTypeSlugs as string[],
        hardSkillSlugs: skills,
      }),
      role,
      programSlug,
      isPublished: membership?.isPublished,
    };

    await reqMemberships({
      method: 'PATCH',
      url: `/memberships/${membership?.id}`,
      data: membershipData,
    });
    if (type === 'soft') {
      navigate('../skills/hard');
    } else {
      if (isGroup) {
        return navigate('../choose-group');
      } else {
        return role === 'guide'
          ? navigate('../preview')
          : navigate('../choose');
      }
    }
  };

  useEffect(() => {
    if (!type || !membership) return;

    const skillOptions = program?.skills?.[skillType]?.flatMap(
      (skill) => skill.skills,
    );

    if (id && relationship && isGroup) {
      const relationshipSkills = (
        relationship?.skills as Partial<ISkill>[]
      )?.map((skill) => skill.fieldSlug);

      const value =
        skillOptions
          ?.filter((option) => relationshipSkills?.includes(option?.slug))
          ?.map((skill) => skill.slug) ?? [];

      return reset({
        [skillType]: {
          fieldType: 'multi-select',
          name: skillType,
          value,
        },
      });
    }

    if (groupValues && isGroup) {
      const groupFormSkills = [
        ...(groupValues?.hardSkillSlugs ?? []),
        ...(groupValues?.softSkillSlugs ?? []),
      ];

      const value =
        skillOptions
          ?.filter(
            (option) => option?.slug && groupFormSkills?.includes(option?.slug),
          )
          ?.map((skill) => skill.slug) ?? [];

      return reset({
        [skillType]: {
          fieldType: 'multi-select',
          name: skillType,
          value,
        },
      });
    }

    const membershipSkills = (membership?.skills as Partial<ISkill>[])?.map(
      (skill) => skill.fieldSlug,
    );

    const value =
      skillOptions
        ?.filter((option) => membershipSkills?.includes(option?.slug))
        ?.map((skill) => skill.slug) ?? [];

    return reset({
      [skillType]: {
        fieldType: 'multi-select',
        name: skillType,
        value,
      },
    });
  }, [
    type,
    reset,
    skillType,
    membership,
    relationship,
    id,
    isGroup,
    groupValues,
    program?.skills,
  ]);

  if (!membership || !role || !type)
    return (
      <ProgressContainer
        onBackButtonClick={() => navigate(-1)}
        percentage={type === 'soft' ? 60 : 80}
      >
        <></>
      </ProgressContainer>
    );

  return (
    <ProgressContainer
      onBackButtonClick={() => navigate(-1)}
      percentage={type === 'soft' ? 60 : 80}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        width={{ xs: '90%', md: '680px' }}
        justifyContent={'space-between'}
        alignItems={'flex-start'}
        gap={{ xs: 1, md: 5 }}
      >
        <SideBarIndicator
          items={[
            {
              text: baseLanguage?.registration?.open_matching?.skills
                ?.soft_skill_label,
            },
            {
              text: baseLanguage?.registration?.open_matching?.skills
                ?.hard_skill_label,
              disabled: skillType === 'soft_skills',
            },
          ]}
        />
        <Stack
          component={'form'}
          onSubmit={handleSubmit(onSubmit)}
          direction={'column'}
          gap={2}
          spacing={0}
          width={{ xs: '100%', md: '480px' }}
        >
          <RoleChip
            label={interpolate(
              baseLanguage?.registration?.open_matching?.common?.preheader,
              {
                roleSingular:
                  program?.program_details?.program_type?.program_type_text
                    ?.common?.[role]?.singular,
              },
            )}
            role={role}
            avatarSrc={role === 'guide' ? guideAvatar : traineeAvatar}
          />
          <TextStack
            spacing={1}
            size="xs"
            heading={{
              text: program?.skills?.text?.[role]?.question_title
                ? program?.skills?.text?.[role]?.question_title
                : baseLanguage?.globals?.skills?.[
                    `${role}_skills_question_title`
                  ],
              variant: 'h2',
              lineHeight: '115%',
            }}
            subtitles={[
              {
                text: program?.skills?.text?.[role]?.question_description
                  ? program?.skills?.text?.[role]?.question_description
                  : baseLanguage?.globals?.skills?.[
                      `${role}_skills_question_description`
                    ],
                color: 'text.secondary',
                variant: 'subtitle1',
              },
              program?.skills?.text?.[`${type}_skills_custom_description`] && {
                text: program?.skills?.text?.[
                  `${type}_skills_custom_description`
                ],
                color: 'text.secondary',
                variant: 'subtitle1',
              },
            ]}
          />
          <SkillCategoriesView
            categories={program?.skills?.[skillType] ?? []}
            control={control}
            placeholder="Search for skills"
            name={skillType}
            min={min}
            max={max}
            maxErrorMessage={maxSkillsErrorMessage}
          />
          <Button
            variant="contained"
            label={baseLanguage?.globals?.common?.continue_button_label}
            color="info"
            type="submit"
            data-cy="register-skills-continue-button"
            loading={isMutatingMemberships || isMutatingRelationships}
            disabled={!isValid}
          />
        </Stack>
      </Stack>
    </ProgressContainer>
  );
}
