// external
import { useParams } from 'react-router-dom';

// internal
import { useOrganizationPrograms } from '@guider-global/sanity-hooks';
import { GuideGroupPreviewPage, GuideRegistrationPreviewPage } from 'pages';
import React from 'react';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useLocalization } from 'hooks';

export const ProgramRegistrationPreviewPage: React.FC = () => {
  const { programSlug = '' } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);

  const programVariation = program?.program_details?.program_variation;
  const isGroupProgram = programVariation === 'group';

  return isGroupProgram ? (
    <GuideGroupPreviewPage />
  ) : (
    <GuideRegistrationPreviewPage />
  );
};
