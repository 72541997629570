// external
import { Box, Divider, Paper, Typography, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

// internal
import {
  getISOStringWithoutMilliseconds,
  getSubDomain,
} from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganization,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { EProgramVariation } from '@guider-global/shared-types';
import { LoadingButton } from '@mui/lab';
import { Asterisk, FormAutocomplete, PageCard, Skeleton } from 'components';
import {
  useLocalization,
  useMixpanelEvents,
  useMobileMediaQuery,
  useSkills,
  useTrackEvent,
} from 'hooks';
import {
  renderFields,
  renderSkills,
} from 'pages/ProgramRegistrationPage/utils';
import React, { useEffect } from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { hideNavbar } from 'store/slices/appSlice';
import {
  IMembershipForm,
  selectMembershipGuideForm,
  setMembershipGuideForm,
} from 'store/slices/formsSlice';
import { Stack } from '@mui/system';
import {
  useCustomFields,
  useMemberships,
} from '@guider-global/front-end-hooks';

export const GuideRegistrationAboutYouPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { trackMixpanelEvent, trackScreenTime } = useMixpanelEvents();

  const { isLoadingMemberships, memberships, isMutatingMemberships } =
    useMemberships({});

  const { isLoadingSkills } = useSkills({ waitForAuthentication: true });

  const { isLoadingCustomFields } = useCustomFields({});

  const { programSlug = '' } = useParams<{
    programSlug: string;
  }>();

  const membershipGuideForm = useAppSelector((state) =>
    selectMembershipGuideForm(state, programSlug),
  );

  const programMemberships = memberships?.filter(
    (membership) => membership.programSlug === programSlug,
  );

  const guideMembership = programMemberships?.find(
    (membership) => membership.role === 'guide',
  );

  useEffect(() => {
    if (guideMembership) {
      navigate(`/programs/${programSlug}/manage/guide`, { replace: true });
    }
  }, [guideMembership, navigate, programSlug]);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: membershipGuideForm,
  });
  const typedControl = control as Control;

  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const { organization } = useOrganization({
    organizationSlug,
  });

  useTrackEvent({
    track: {
      eventKey: 'program-guide-registration-viewed',
      eventParams: {
        organization: { organizationSlug },
        program: {
          organizationSlug,
          programSlug,
        },
      },
    },
  });

  useEffect(() => {
    trackMixpanelEvent('Guide Program Registration - Viewed', {
      'Date viewed': getISOStringWithoutMilliseconds(),
      'Program ID': programSlug,
    });
  }, [trackMixpanelEvent, programSlug]);

  useEffect(() => {
    dispatch(hideNavbar(true));
  }, [dispatch]);

  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);

  useEffect(() => {
    if (!program) {
      navigate('./../');
    }
  }, [navigate, program]);

  if (!program) {
    return <></>;
  }

  const isGoalsDisabled = program?.registration?.goals?.guide_disable_goals;
  const registrationQuestions = program?.registration?.registration_questions;
  const customGoalCategories =
    program.registration?.goals?.custom_goal_categories;
  const selectedGoalSource =
    customGoalCategories && customGoalCategories.length > 0
      ? customGoalCategories
      : organization?.goal_categories?.categories;

  // Get Sanity Goal Categories

  const goalCategories = selectedGoalSource?.map((goalCategory) => ({
    label: goalCategory.goal_category_name,
    value: goalCategory.goal_category_slug?.current,
  }));
  // Events
  const goalsValidation = (value: unknown) => {
    const valueLength = (value as string[]).length;
    if (valueLength < 1) return false;
    return true;
  };

  const isGroupProgram =
    program.program_details?.program_variation === EProgramVariation.Group;
  const programType = program.program_details?.program_type;
  const programVariation =
    programType?.program_type_text?.variations?.individual;

  const skillsConfig = program?.registration?.skills;
  const isSkillsEnabled = skillsConfig?.enable_skills_question;

  const onSubmit: SubmitHandler<IMembershipForm> = async (data) => {
    dispatch(setMembershipGuideForm({ programSlug, data }));
    trackScreenTime('Guide Program Registration - About you Completed');
    navigate('./preview');
  };

  const renderForm = () =>
    program && (
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ padding: isMobile ? '0 16px' : '0 24px' }}
      >
        {isSkillsEnabled &&
          renderSkills({
            skillsConfig,
            typedControl,
            errors,
            pageName: 'GuideRegistrationAboutYouPage',
            role: 'guide',
            isGroupProgram,
          })}
        {!isGoalsDisabled && goalCategories && !isGroupProgram && (
          <Stack direction={'column'} mt={6}>
            <Typography variant="h6">
              {isSkillsEnabled ? '2. ' : '1. '}
              {
                program.program_details?.program_type?.program_type_text
                  .variations?.individual?.registration?.registration_guide
                  ?.registration_guide_goals?.registration_guide_goals_title
              }
              <Asterisk />
            </Typography>
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ whiteSpace: 'pre-line' }}
            >
              {
                program.program_details?.program_type?.program_type_text
                  .variations?.individual?.registration?.registration_guide
                  ?.registration_guide_goals
                  ?.registration_guide_goals_description
              }
            </Typography>

            <FormAutocomplete
              multiple
              defaultValue={[]}
              name={'goalCategorySlugs'}
              control={typedControl}
              label={baseLanguage?.globals?.goals?.goals ?? 'Goals'}
              errors={errors}
              options={goalCategories}
              rules={{
                required: true,
                validate: goalsValidation,
              }}
            />
          </Stack>
        )}

        {renderFields({
          registrationQuestions,
          isSkillsEnabled,
          typedControl,
          errors,
          pageName: 'GuideRegistrationAboutYouPage',
          role: 'guide',
          isGroupProgram,
          isGoalsDisabled,
        })}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            mt: 6,
          }}
        >
          <LoadingButton
            variant="contained"
            color="info"
            size="large"
            type="submit"
            disabled={!isValid}
            sx={{
              color: isValid ? 'white' : 'inherit',
            }}
            fullWidth={isMobile}
            loading={
              isLoadingCustomFields ||
              isLoadingSkills ||
              isLoadingMemberships ||
              isMutatingMemberships
            }
            data-cy={`pages_GuideRegistrationAboutYouPage_continue-button`}
          >
            {baseLanguage?.globals?.common?.continue_button_label ?? 'Continue'}
          </LoadingButton>
        </Box>
      </form>
    );

  return (
    <Paper
      sx={{
        pt: isMobile ? 0 : 4,
        pb: 4,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
        ...(isMobile && { borderRadius: '0px' }),
      }}
    >
      <PageCard
        title={
          programVariation?.registration?.registration_guide
            ?.registration_guide_about_you
            ?.registration_guide_about_you_title ?? ''
        }
        subtitle={
          programVariation?.registration?.registration_guide
            ?.registration_guide_about_you
            ?.registration_guide_about_you_description ?? ''
        }
      />
      {!isMobile && <Divider sx={{ my: 4, mx: 3 }} />}
      {!program ? <Skeleton /> : renderForm()}
    </Paper>
  );
};
