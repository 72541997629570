// external
import { useAuth } from '@guider-global/auth-hooks';
import ArrowBack from '@mui/icons-material/ArrowBack';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
  Typography,
  useScrollTrigger,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// store
import { useAppDispatch } from 'store/hooks';
import { hideNavbar, showNavbar } from 'store/slices/appSlice';

// hooks
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { useLocalization, useMobileMediaQuery } from 'hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useRelationships } from '@guider-global/front-end-hooks';

interface ProgramRegistrationPageProps {
  children: React.ReactElement;
}

export const ProgramRegistrationPage = ({
  children,
}: ProgramRegistrationPageProps) => {
  useAuth({});
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(hideNavbar(true));

    return () => {
      dispatch(showNavbar(true));
    };
  }, [dispatch]);

  const { palette, transitions } = useTheme();
  const isMobile = useMobileMediaQuery();

  const { isLoadingRelationships } = useRelationships({});

  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const { programSlug = '', role } = useParams();
  const isInvalidRole = role !== 'guide' && role !== 'trainee';

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);

  const { baseLanguage } = useBaseLanguage({ localeCode });

  useEffect(() => {
    if (!program || !role || isInvalidRole) {
      navigate('./../');
    }
  }, [isInvalidRole, navigate, program, role]);

  if (!program || !role || isInvalidRole) {
    return <></>;
  }

  const programType = program.program_details?.program_type;
  const programVariation = program.program_details?.program_variation;
  const isGroupProgram = programVariation === 'group';

  const programVariations = programType?.program_type_text?.variations;

  const individualCopy = programVariations?.individual;
  const groupCopy = programVariations?.group;

  const isGuide = role === 'guide';

  const guideTitle = isGroupProgram
    ? groupCopy?.registration?.registration_guide?.registration_guide_title
    : individualCopy?.registration?.registration_guide
        ?.registration_guide_title;

  const traineeTitle = isGroupProgram
    ? groupCopy?.registration?.registration_trainee?.registration_trainee_title
    : individualCopy?.registration?.registration_trainee
        ?.registration_trainee_title;

  return (
    <>
      <AppBar
        elevation={isMobile && scrollTrigger ? 3 : 0}
        sx={{
          backgroundColor: isMobile && scrollTrigger ? 'white' : 'transparent',
          transition: `all ${transitions.duration.standard}ms ${transitions.easing.easeInOut}`,
        }}
      >
        <Toolbar>
          <Button
            data-cy="pages_ProgramRegistrationPage_back-button"
            variant="text"
            startIcon={<ArrowBack />}
            fullWidth={false}
            disabled={isLoadingRelationships}
            onClick={() => navigate(-1)}
            sx={{
              color: palette.default.text.primary,
            }}
          >
            {baseLanguage?.globals?.common?.go_back_button_label}
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: 13,
        }}
      >
        <Grid
          container
          sx={{ width: isMobile ? '100%' : '70%', maxWidth: '1440px' }}
          spacing={isMobile ? 0 : 3}
          rowSpacing={isMobile ? 2 : 0}
        >
          <Grid item xs>
            <Typography
              variant="h6"
              color={palette.paper.text.primary}
              sx={{ mx: isMobile ? 2 : 0 }}
            >
              {program.metadata.program_name}
            </Typography>
            <Typography
              variant="h4"
              color={palette.paper.text.primary}
              sx={{ mx: isMobile ? 2 : 0, mb: 3 }}
            >
              {isGuide ? guideTitle : traineeTitle}
            </Typography>
            {children}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
