// external
import { Box, Divider, Paper, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

// internal
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganizationPrograms,
} from '@guider-global/sanity-hooks';
import { EProgramVariation } from '@guider-global/shared-types';
import { LoadingButton } from '@mui/lab';
import { PageCard } from 'components';
import { ProgramManageGuideDescription } from 'components/ProgramManageGuideDescription';
import { ProgramManageGuideTitle } from 'components/ProgramManageGuideTitle';
import {
  useLocalization,
  useMobileMediaQuery,
  useSkills,
  useTrackEvent,
} from 'hooks';
import {
  renderFields,
  renderSkills,
} from 'pages/ProgramRegistrationPage/utils';
import React, { useEffect } from 'react';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { hideNavbar } from 'store/slices/appSlice';
import {
  IGroupGuideForm,
  selectGroupGuideForm,
  setGroupsGuideForm,
} from 'store/slices/formsSlice';
import {
  useCustomFields,
  useMemberships,
} from '@guider-global/front-end-hooks';

export const GuideGroupCreatePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoadingMemberships, memberships, isMutatingMemberships } =
    useMemberships({});

  const { isLoadingSkills } = useSkills({ waitForAuthentication: true });

  const { isLoadingCustomFields } = useCustomFields({});

  const { programSlug = '' } = useParams<{
    programSlug: string;
  }>();

  const groupGuideForm = useAppSelector((state) =>
    selectGroupGuideForm(state, programSlug),
  );

  const programMemberships = memberships?.filter(
    (membership) => membership.programSlug === programSlug,
  );

  const guideMembership = programMemberships?.find(
    (membership) => membership.role === 'guide',
  );

  useEffect(() => {
    if (guideMembership) {
      navigate(`/programs/${programSlug}/manage/guide`);
    }
  }, [guideMembership, navigate, programSlug]);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: groupGuideForm,
  });

  const typedControl = control as unknown as Control;

  const organizationSlug = getSubDomain();

  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  useTrackEvent({
    track: {
      eventKey: 'program-guide-registration-viewed',
      eventParams: {
        organization: { organizationSlug },
        program: {
          organizationSlug,
          programSlug,
        },
      },
    },
  });

  useEffect(() => {
    dispatch(hideNavbar(true));
  }, [dispatch]);

  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);

  useEffect(() => {
    if (!program) {
      navigate('./../');
    }
  }, [navigate, program]);

  if (!program) {
    return <></>;
  }

  const pageName = 'GuideGroupCreatePage';

  const programRegistration = program.registration;
  const registrationQuestions = programRegistration?.registration_questions;

  const isGroupProgram =
    program.program_details?.program_variation === EProgramVariation.Group;
  const programType = program.program_details?.program_type;

  const programVariations = programType?.program_type_text?.variations;

  const groupCopy = programVariations?.group;

  const skillsConfig = program.registration?.skills;
  const isSkillsEnabled = skillsConfig?.enable_skills_question;

  const onSubmit: SubmitHandler<IGroupGuideForm> = async (data) => {
    dispatch(setGroupsGuideForm({ programSlug, data }));
    navigate('./preview');
  };

  return (
    <Paper
      sx={{
        pt: isMobile ? 0 : 4,
        pb: 4,
        borderTop: `8px solid ${theme.palette.secondary.main}`,
        ...(isMobile && { borderRadius: '0px' }),
      }}
    >
      <PageCard
        title={
          groupCopy?.registration?.registration_guide
            ?.registration_guide_about_you
            ?.registration_guide_about_you_title ?? ''
        }
        subtitle={
          groupCopy?.registration?.registration_guide
            ?.registration_guide_about_you
            ?.registration_guide_about_you_description ?? ''
        }
      />
      {!isMobile && <Divider sx={{ my: 4, mx: 3 }} />}
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ padding: isMobile ? '0 16px' : '0 24px' }}
      >
        <ProgramManageGuideTitle
          programRegistration={programRegistration}
          pageName={pageName}
          control={typedControl}
          errors={errors}
        />
        <ProgramManageGuideDescription
          programRegistration={programRegistration}
          pageName={pageName}
          control={typedControl}
          errors={errors}
        />
        {isSkillsEnabled &&
          renderSkills({
            skillsConfig,
            typedControl,
            errors,
            pageName,
            role: 'guide',
            isGroupProgram,
          })}
        {renderFields({
          registrationQuestions,
          isSkillsEnabled,
          typedControl,
          errors,
          pageName,
          role: 'guide',
          isGroupProgram,
        })}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            mt: 6,
          }}
        >
          <LoadingButton
            variant="contained"
            color="info"
            size="large"
            type="submit"
            disabled={!isValid}
            sx={{
              color: isValid ? 'white' : 'inherit',
            }}
            fullWidth={isMobile}
            loading={
              isLoadingCustomFields ||
              isLoadingSkills ||
              isLoadingMemberships ||
              isMutatingMemberships
            }
            data-cy={`pages_GuideGroupCreatePage_continue-button`}
          >
            {baseLanguage?.globals?.common?.continue_button_label ?? 'Continue'}
          </LoadingButton>
        </Box>
      </form>
    </Paper>
  );
};
