import { Box, Divider, Paper, ThemeProvider, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageCard } from 'components';
import { useAppDispatch } from 'store/hooks';
import { useLocalization, useMobileMediaQuery } from 'hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganizationPrograms,
  useOrganization,
} from '@guider-global/sanity-hooks';
import { IMembership } from '@guider-global/shared-types';
import { Stack, StaticAlert, Text, theme } from '@guider-global/ui';
import { LoadingButton } from '@mui/lab';
import { showAppAlert } from 'store/slices/appSlice';
import { deepMerge } from 'utils';
import GoalCategoriesView from 'views/Registration/GoalCategoriesView';
import { useMemberships } from '@guider-global/front-end-hooks';

export const ProgramRegistrationGoalsPage: React.FC = () => {
  // Redux
  const dispatch = useAppDispatch();
  const organizationSlug = getSubDomain();

  // React Router
  const navigate = useNavigate();
  const { programSlug = '', role } = useParams<{
    programSlug: string;
    role: 'guide' | 'trainee';
  }>();

  // Styling
  const organizationTheme = useTheme();
  const isMobile = useMobileMediaQuery();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );

  // Base Language
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  // Organization
  const subdomain = getSubDomain();
  const { organization } = useOrganization({
    organizationSlug: subdomain,
  });

  // Programs
  const { getProgram } = useOrganizationPrograms({
    organizationSlug,
    localeCode,
  });
  const program = getProgram(programSlug);

  // Programs - baseLanguage
  const programType = program?.program_details?.program_type?.program_type_text;
  const programRegistration = programType?.variations?.individual?.registration;
  const selectGoalLabel =
    program?.registration?.goals?.select_goals_custom_label ||
    baseLanguage?.globals?.goals?.select_goals_label;
  const trainee_min_selected_goals =
    program?.registration?.goals?.trainee_min_selected_goals;
  const trainee_max_selected_goals =
    program?.registration?.goals?.trainee_max_selected_goals;

  // Internal state
  const [selectedGoalCategories, setSelectedGoalCategories] = useState<
    string[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string | undefined>(undefined);
  const [isMembershipLoaded, setIsMembershipLoaded] = useState(false);

  // - Memberships
  const { reqMemberships, memberships, membershipsRevalidate } = useMemberships(
    {},
  );

  useEffect(() => {
    if (!memberships?.length) {
      membershipsRevalidate();
    }
  }, [memberships, membershipsRevalidate]);

  const programMemberships = memberships?.filter(
    (membership) => membership.programSlug === programSlug,
  );
  const membership = programMemberships?.find(
    (membership) => membership.role === role,
  );

  useEffect(() => {
    if (!membership?.goalCategories) return;
    setSelectedGoalCategories(
      membership.goalCategories.map((goalCategory) => goalCategory.fieldSlug),
    );
    setIsMembershipLoaded(true);
  }, [membership]);

  const customGoalCategories =
    program?.registration?.goals?.custom_goal_categories;

  const selectedGoalSource =
    customGoalCategories && customGoalCategories.length > 0
      ? customGoalCategories
      : organization?.goal_categories?.categories;

  const goalCategories = selectedGoalSource?.map((goalCategory) => ({
    label: goalCategory.goal_category_name,
    slug: goalCategory.goal_category_slug?.current,
  }));

  if (!program || !role || !goalCategories || !isMembershipLoaded) {
    return null;
  }

  function handleGoalCategoriesToggle(goalCategorySlugs: string[]) {
    setSelectedGoalCategories(goalCategorySlugs);
    checkGoalLimit(goalCategorySlugs);
  }

  function checkGoalLimit(goalCategorySlugs: string[]) {
    if (
      trainee_max_selected_goals &&
      goalCategorySlugs.length > trainee_max_selected_goals
    ) {
      setAlert(
        baseLanguage?.globals?.goals?.max_goals_validation_message ??
          `You've selected too many goals`,
      );
    } else {
      setAlert(undefined);
    }
  }

  async function handleGoalCategoriesUpdate() {
    if (!selectedGoalCategories || isLoading) {
      return;
    }
    setIsLoading(true);

    const data: Partial<IMembership> & { goalCategorySlugs: string[] } = {
      role: role,
      isPublished: true,
      programSlug,
      goalCategorySlugs: selectedGoalCategories,
    };

    const membershipsResult = await reqMemberships({
      method: 'PATCH',
      url: `/memberships/${membership?.id}`,
      data,
    });
    await reqMemberships({
      url: `/memberships`,
    });

    if (membershipsResult.status === 'success') {
      navigate('../choose');
    } else {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: membershipsResult.errors?.at(0)?.message,
          timeout: 3000,
        }),
      );
    }
    setIsLoading(false);
  }

  const disableContinueButton =
    !!(
      trainee_min_selected_goals &&
      selectedGoalCategories.length < trainee_min_selected_goals
    ) ||
    !!(
      trainee_max_selected_goals &&
      selectedGoalCategories.length > trainee_max_selected_goals
    );

  return (
    <Paper
      sx={{
        pt: isMobile ? 0 : 4,
        pb: 4,
        borderTop: `8px solid ${combinedPalette.secondary.main}`,
        borderRadius: isMobile ? 0 : '4px',
      }}
    >
      <PageCard
        title={
          programRegistration?.registration_trainee?.registration_trainee_goals
            ?.registration_trainee_goals_title ?? ''
        }
        subtitle={
          programRegistration?.registration_trainee?.registration_trainee_goals
            ?.registration_trainee_goals_description ?? ''
        }
      />
      {!isMobile && <Divider sx={{ my: 4, mx: 3 }} />}
      <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
        <Box
          sx={{ my: 4, mx: 3 }}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <Stack direction={'column'} width={{ xs: '100%', md: '60%' }}>
            <Text text={selectGoalLabel} fontSize={'16px'} fontWeight={500} />
            {alert && (
              <StaticAlert
                message={alert}
                fullWidth
                sx={{
                  backgroundColor: '#FFEBEB',
                  color: combinedPalette.error.main,
                }}
              />
            )}
            {goalCategories && (
              <GoalCategoriesView
                goalCategories={goalCategories}
                selectedGoalCategories={selectedGoalCategories}
                onSelected={(goalCategorySlugs) =>
                  handleGoalCategoriesToggle(goalCategorySlugs)
                }
                buttonLabels={{
                  more:
                    baseLanguage?.globals?.common?.show_more_button_label ??
                    'Show more',
                  less:
                    baseLanguage?.globals?.common?.show_less_button_label ??
                    'Show less',
                }}
              />
            )}
          </Stack>
        </Box>
      </ThemeProvider>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          mt: 3,
          px: isMobile ? 2 : 3,
        }}
      >
        <LoadingButton
          variant="contained"
          color="info"
          size="large"
          loading={isLoading}
          fullWidth={isMobile}
          onClick={handleGoalCategoriesUpdate}
          disabled={disableContinueButton}
          data-cy="goal-categories-continue-button"
        >
          {baseLanguage?.globals?.common?.continue_button_label}
        </LoadingButton>
      </Box>
    </Paper>
  );
};
